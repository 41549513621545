import { inject, Injectable } from '@angular/core';
import { RecordType, CreateWorkLogModel, ManufacturingOperationDetailModel } from '@core/api/models/timesheets';
import { ManufacturingOperationDataService } from '@core/api/services/timesheets/manufacturing-operation-data.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { map, ReplaySubject, Subject } from 'rxjs';

const timeTrackerLocalStorageKey = 'time-tracker-tasks';

export interface TimeTrackerItem {
  taskNumber: string;
  start?: string;
  paused?: string;
  triggeredFrom?: string;
  meta?: any;
}

@Injectable({
  providedIn: 'root',
})
export class TimeTrackerNewService {
  private readonly timesheetService = inject(ManufacturingOperationDataService);
  private readonly snackbarService = inject(SnackbarService);

  private tasks = new ReplaySubject<TimeTrackerItem[]>();
  private _tasks!: TimeTrackerItem[];

  private onStart = new Subject<{ id: string; state: boolean }>();
  private onPause = new Subject<{ id: string }>();
  private onStop = new Subject<{ id: string }>();
  private openPopup = new Subject<void>();

  public onStart$ = this.onStart.asObservable();
  public onPause$ = this.onPause.asObservable();
  public onStop$ = this.onStop.asObservable();
  public openPopup$ = this.openPopup.asObservable();
  public workInProgress$ = this.tasks.pipe(map(tasks => tasks.some(t => !!t.start && t.paused !== 'true')));
  public taskInProgress$ = this.tasks.pipe(
    map(tasks =>
      tasks.reduce((arr: any, task) => {
        arr[task.taskNumber] = !!task.start && task.paused !== 'true';
        return arr;
      }, {}),
    ),
  );
  public isActive$ = this.tasks.pipe(map(d => d.length > 0));

  constructor() {
    this.tasks.subscribe(tasks => {
      this._tasks = tasks;
      this.saveDataToLocalStorage(tasks);
    });
    this.tasks.next(this.getDataFromLocalStorage());
  }

  /* Start specified task */
  public startWorkTask(task: TimeTrackerItem, cb?: () => void) {
    this.saveTask({ ...task, start: Date.now().toString(), paused: 'false' });

    setTimeout(() => {
      // need to wait before panel shown up to trigger open
      this.openPopup.next();
    }, 0);

    task.triggeredFrom === 'daily-plan' && this.onStart.next({ id: task.meta.dailyPlanId, state: false });
    cb && cb();
  }

  /* Continue specified task */
  public continueWorkTask(task: TimeTrackerItem, operation: ManufacturingOperationDetailModel, cb?: () => void) {
    this.saveTask({ ...task, start: Date.now().toString(), paused: 'false' });

    task.triggeredFrom === 'daily-plan' && this.onStart.next({ id: task.meta.dailyPlanId, state: true });
    cb && cb();
  }

  /* Pause specified task */
  public pauseWorkTask(task: TimeTrackerItem, operation: ManufacturingOperationDetailModel, cb?: () => void) {
    this.logWork(task, operation, () => {
      this.saveTask({ ...task, start: Date.now().toString(), paused: 'true' });

      task.triggeredFrom === 'daily-plan' && this.onPause.next({ id: task.meta.dailyPlanId });
      cb && cb();
    });
  }

  /* Stop specified task */
  public stopWorkTask(task: TimeTrackerItem, operation: ManufacturingOperationDetailModel, cb?: () => void) {
    task.triggeredFrom === 'daily-plan' && this.onStop.next({ id: task.meta.dailyPlanId });
    this.logWork(task, operation, () => {
      this.removeTask(task);

      task.triggeredFrom === 'daily-plan' && this.onStop.next({ id: task.meta.dailyPlanId });
      cb && cb();
    });
  }

  /* Revert work time for specified task */
  public revertWorkTask(task: TimeTrackerItem, operation: ManufacturingOperationDetailModel) {
    this.saveTask({ ...task, start: Date.now().toString(), paused: 'true' });
  }

  /**
   * Add or updates already existing task to tasks array
   * @param task Task
   */
  public saveTask(task: TimeTrackerItem) {
    const foundTaskIndex = this.getTaskIndex(task.taskNumber!);
    if (foundTaskIndex !== -1) {
      this._tasks[foundTaskIndex] = { ...this._tasks[foundTaskIndex], ...task };
      this.tasks.next(this._tasks);
    } else {
      this.tasks.next([...this._tasks, task]);
    }
  }

  /**
   * Removes task from tasks array
   * @param task Task
   */
  public removeTask(task: TimeTrackerItem) {
    const foundTaskIndex = this.getTaskIndex(task.taskNumber!);
    if (foundTaskIndex !== -1) {
      this._tasks.splice(foundTaskIndex, 1);
      this.tasks.next(this._tasks.splice(foundTaskIndex, 1));
    }
  }

  public getTaskIndex(taskNumber: string) {
    return this._tasks.findIndex(t => t.taskNumber === taskNumber);
  }

  public getTasks() {
    return this.tasks.asObservable();
  }

  private logWork(task: TimeTrackerItem, operation: ManufacturingOperationDetailModel, cb: () => void) {
    if (!operation) throw 'Operation is not loaded.';

    // TODO remove
    const durationInMinutes = this.calculateWorkTime(task.start as string, Date.now().toString()) || 1;

    if (!durationInMinutes) {
      cb && cb();
      return;
    }

    const workLog = {
      durationInMinutes,
      recordType: RecordType.Claim,
    } as CreateWorkLogModel;
    this.timesheetService.createWorkLog(operation.operationClaim.id, workLog).subscribe({
      next: () => {
        cb && cb();
        this.snackbarService.addSnackbarAndTranslateIt({
          message: 'domains.timesheets.common.workLogged',
          iconType: 'success',
        });
      },
      error: () => {
        this.snackbarService.addSnackbarAndTranslateIt({
          message: 'domains.timesheets.errors.logWork',
          iconType: 'error',
        });
      },
    });
  }

  private getDataFromLocalStorage() {
    const defaultValue: any[] = [];
    try {
      const d = localStorage.getItem(timeTrackerLocalStorageKey);
      const parsedData = JSON.parse(d as string);
      return parsedData || defaultValue;
    } catch (e) {
      return defaultValue;
    }
  }

  private saveDataToLocalStorage(data: any[]) {
    localStorage.setItem(timeTrackerLocalStorageKey, JSON.stringify(data));
  }

  private calculateWorkTime(start: string, end: string) {
    return Math.floor((new Date(+end).getTime() - new Date(+start).getTime()) / 1000 / 60);
  }
}
